import { useState } from "react";
import { Row, Container, Col } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion"
import Head from "next/head";
import striptags from "striptags";
import { parseHtml } from "../../../utils/common";
import handleViewport from "react-in-viewport";

function addFaqJson(faqs) {
	let json = {
		"@context": "https://schema.org",
		"@type": "FAQPage",
		"mainEntity": []
	};
	
	faqs?.faq.map((faq, index) => {
		json.mainEntity.push(
			{
				"@type": "Question",
				"name": faq.question,
				"acceptedAnswer": {
					"@type": "Answer",
					"text": striptags(faq.answer)
				}
			}
		)
	})
	
	return {
		__html: `${JSON.stringify(json, null, 2)}`,
	};
}

function FaqSection(props) {
	const { forwardedRef, inViewport } = props
    const faqs = props.faqs ? props.faqs : props.block;
    const [activeindex, setactiveindex] = useState();
    
    const handleClick = (index) => {
        if (index == activeindex) {
            setactiveindex(null);
        } else {
            setactiveindex(index);
        }
    }

    return (
		<>
			<Head>
				<script
					type="application/ld+json"
					dangerouslySetInnerHTML={addFaqJson(faqs)}
					key="faq-jsonld"
				/>
			</Head>

			<section ref={forwardedRef} style={{minHeight: '500px'}} className="faq-section">
				{inViewport && (
					<div className="faq-inner">
						<Container>
							<Row>
								<Col lg={12} >
									<div className="heading">
										<h3>{faqs?.short_title}</h3>
										<h2>{faqs?.title}</h2>
									</div>
								</Col>
							</Row>

							<Row>
								<Col lg={12} >
									<Accordion>
										<Row>
											<Col lg={6} >
												{
													faqs?.faq.map((faq, index) => {
														return (
															<div key={index}>
																{(index % 2) === 0 &&
																	<Accordion.Item eventKey={index} onClick={() => handleClick(index)}
																		className={activeindex === index ? "active" : null}>
																		<Accordion.Header>
																			<span>{faq.question}</span>
																		</Accordion.Header>
																		<Accordion.Body>
																			<span>{parseHtml(faq.answer)}</span>
																		</Accordion.Body>
																	</Accordion.Item>
																}
															</div>
														)
													})
												}
											</Col>
											<Col lg={6} >
												{
													faqs?.faq.map((faq, index) => {
														return (
															<div key={index}>
																{(index % 2) === 1 &&
																	<Accordion.Item eventKey={index} onClick={() => handleClick(index)}
																		className={activeindex === index ? "active" : null}>
																		<Accordion.Header>
																			<span>{faq.question}</span>
																		</Accordion.Header>
																		<Accordion.Body>
																			<span>{parseHtml(faq.answer)}</span>
																		</Accordion.Body>
																	</Accordion.Item>
																}
															</div>
														)
													})
												}
											</Col>
										</Row>
									</Accordion>
								</Col>
							</Row>
						</Container>
					</div>
				)}
			</section>
		</>
	);
}

const FaqSectionViewportBlock = handleViewport(FaqSection);

export default FaqSectionViewportBlock;

